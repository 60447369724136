import "../styles/globals.css";
import "../styles/swal.css";
import "../styles/datepicker.css";
import "react-18-image-lightbox/style.css";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import { getCookie } from "cookies-next";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { MenuSidebar } from "../app/helpers/MenuList";
const _ = require("lodash");

type Roles = {
  feature: string;
  actions: string[];
};

type SualProps = AppProps & {
  general: {
    roles: Roles[];
  };
};

const guestRoutes = [
  "sign-in",
  "account-recovery",
  "account-recovery/send",
  "account-recovery/verify",
  "account-recovery/verify-success",
  "account-recovery/verify-expired",
  "template-recovery",
  "404",
  "500",
];

function MyApp({ Component, pageProps, general }: SualProps) {
  const router = useRouter();
  useEffect(() => {
    if (general.roles.length > 0) {
      const url = router.asPath;

      for (const elem of MenuSidebar) {
        for (const child of elem.items) {
          if (
            child.feature !== "fixed" &&
            !guestRoutes.includes(url.replace("/", "")) &&
            url.startsWith(child.prefix)
          ) {
            if (!_.some(general.roles, { feature: child.feature })) {
              router.push("/404");
            }
          } else if (child.link === "/" && url === "/") {
            if (!_.some(general.roles, { feature: "transaction" })) {
              const cek = _.find(MenuSidebar, (e: any) => {
                return _.find(e.items, { feature: general.roles[0].feature });
              });
              if (cek) {
                const searchItems = _.find(cek.items, {
                  feature: general.roles[0].feature,
                });
                router.push(searchItems.link);
              }
            }
          } else if (child.id === "user" && url.startsWith(child.prefix)) {
            const cek_user = _.find(general.roles, { feature: "buyer" });
            const cek_fac = _.find(general.roles, {
              feature: "facility_manager",
            });
            if (url === "/user/buyer") {
              if (!cek_user) {
                router.push("/404");
              }
            }

            if (url === "/user/facility-manager") {
              if (!cek_fac) {
                router.push("/404");
              }
            }
          }
        }
      }
    }
  }, [general, router]);

  return <Component {...pageProps} />;
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  await App.getInitialProps(appContext);

  let roles = [] as Roles[];
  const cekLocal: any =
    getCookie("b/profile", {
      req: appContext.ctx.req,
      res: appContext.ctx.res,
    }) || "";

  if (cekLocal) {
    roles = JSON.parse(cekLocal).role.permissions;
  }

  return {
    general: {
      roles,
    },
  };
};

export default MyApp;
