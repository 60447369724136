import { FC } from "react";
import {
  ActivityBoldIcon,
  ActivityIcon,
  BuildingAdminBoldIcon,
  BuildingAdminIcon,
  Buildings2BoldIcon,
  Buildings2Icon,
  CalendarBoldIcon,
  CalendarIcon,
  Chart2BoldIcon,
  Chart2Icon,
  ChartBoldIcon,
  ChartIcon,
  FavoriteChartBoldIcon,
  FavoriteChartIcon,
  HomeBoldIcon,
  HomeIcon,
  House2BoldIcon,
  House2Icon,
  InfoCircleBoldIcon,
  InfoCircleIcon,
  KeySquareBoldIcon,
  KeySquareIcon,
  Note2BoldIcon,
  Note2Icon,
  ReceiptBoldIcon,
  ReceiptIcon,
  SecurityBoldIcon,
  SecurityIcon,
  SignOutIcon,
  TagBoldIcon,
  TagLineIcon,
  UsersBoldIcon,
  UsersIcon,
} from "../components/commons/icon/SvgIcon";

export const MenuFooter = [
  {
    title: " © Storage Auction List",
    link: "/",
  },
  {
    title: "Contact",
    link: "#",
  },
  {
    title: "FAQ",
    link: "#",
  },
  {
    title: "Privacy Policy",
    link: "#",
  },
  {
    title: "Term and Condition",
    link: "#",
  },
] as {
  title: string;
  link: string;
}[];

export const MenuSidebar = [
  {
    id: "title-dashboard",
    title: "Dashboard",
    feature: ["transaction"],
    items: [
      {
        id: "dashboard",
        title: "Dashboard",
        link: "/",
        prefix: "/dashboard",
        active: false,
        Icon: HomeIcon,
        IconActive: HomeBoldIcon,
        feature: "transaction",
      },
    ],
  },
  {
    id: "title-storage",
    title: "Storage",
    feature: [
      "facility",
      "auction",
      "caravan_auction",
      "buyer",
      "facility_manager",
      "storage_tag"
    ],
    items: [
      {
        id: "storage_tag",
        title: "Tags",
        link: "/storage-tag",
        prefix: "/storage-tag",
        active: false,
        Icon: TagLineIcon,
        IconActive: TagBoldIcon,
        feature: "storage_tag",
      },
      {
        id: "facility",
        title: "Facilities",
        link: "/facility",
        prefix: "/facility",
        active: false,
        Icon: BuildingAdminIcon,
        IconActive: BuildingAdminBoldIcon,
        feature: "facility",
      },
      {
        id: "auctions",
        title: "Auctions",
        link: "/auction",
        prefix: "/auction",
        active: false,
        Icon: House2Icon,
        IconActive: House2BoldIcon,
        feature: "auction",
      },
      {
        id: "carava-auctions",
        title: "Caravan Auctions",
        link: "/caravan-auction",
        prefix: "/caravan-auction",
        active: false,
        Icon: Buildings2Icon,
        IconActive: Buildings2BoldIcon,
        feature: "caravan_auction",
      },
      {
        id: "upcoming-auctions",
        title: "Upcoming Auctions",
        link: "/upcoming-auction",
        prefix: "/upcoming-auction",
        active: false,
        Icon: CalendarIcon,
        IconActive: CalendarBoldIcon,
        feature: "auction",
      },
      {
        id: "user",
        title: "Users",
        link: "/user/buyer",
        prefix: "/user",
        active: false,
        Icon: UsersIcon,
        IconActive: UsersBoldIcon,
        // feature: "buyer",
        feature: "fixed",
      },
    ],
  },
  {
    id: "title-subscription",
    title: "Subscription",
    feature: ["pricing_plan", "transaction"],
    items: [
      {
        id: "pricing-plan",
        title: "Pricing Plans",
        link: "/setting/pricing-plan",
        prefix: "/setting/pricing-plan",
        active: false,
        Icon: Note2Icon,
        IconActive: Note2BoldIcon,
        feature: "pricing_plan",
      },
      {
        id: "transactions",
        title: "Transactions",
        link: "/transaction",
        prefix: "/transaction",
        active: false,
        Icon: ReceiptIcon,
        IconActive: ReceiptBoldIcon,
        feature: "transaction",
      },
    ],
  },
  {
    id: "title-admin-account",
    title: "Admin Account",
    feature: ["admin", "role_and_permission"],
    items: [
      {
        id: "admin",
        title: "Admins",
        link: "/admin",
        prefix: "/admin",
        active: false,
        Icon: SecurityIcon,
        IconActive: SecurityBoldIcon,
        feature: "admin",
      },
      {
        id: "permission",
        title: "Roles & Permissions",
        link: "/role-and-permission",
        prefix: "/role-and-permission",
        active: false,
        Icon: KeySquareIcon,
        IconActive: KeySquareBoldIcon,
        feature: "role_and_permission",
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    feature: [
      "report_subscribed_area",
      "report_storage",
      "report_storage",
      "report_user",
      "report_admin",
    ],
    items: [
      {
        id: "subscribed",
        title: "Subscribed Areas",
        link: "/report/subscribed",
        prefix: "/report/subscribed",
        active: false,
        Icon: FavoriteChartIcon,
        IconActive: FavoriteChartBoldIcon,
        feature: "report_subscribed_area",
      },
      {
        id: "facility-auction",
        title: "Facility & Auction",
        link: "/report/storage",
        prefix: "/report/storage",
        active: false,
        Icon: Chart2Icon,
        IconActive: Chart2BoldIcon,
        feature: "report_storage",
      },
      {
        id: "users-own",
        title: "Users Own",
        link: "/report/user",
        prefix: "/report/user",
        active: false,
        Icon: ChartIcon,
        IconActive: ChartBoldIcon,
        feature: "report_user",
      },
      {
        id: "admins-own",
        title: "Admins Own",
        link: "/report/admin",
        prefix: "/report/admin",
        active: false,
        Icon: ActivityIcon,
        IconActive: ActivityBoldIcon,
        feature: "report_admin",
      },
    ],
  },
  {
    id: "title-setting",
    title: "Setting",
    feature: ["fixed"],
    items: [
      {
        id: "signout",
        title: "Sign Out",
        link: "#",
        active: false,
        Icon: SignOutIcon,
        IconActive: SignOutIcon,
        feature: "fixed",
      },
    ],
  },
] as {
  id: string;
  title: string;
  feature: string[];
  items: [
    {
      id: string;
      title: string;
      link: string;
      prefix: string;
      active: boolean;
      Icon: FC;
      IconActive: FC;
      feature: string;
    }
  ];
}[];

export const allPathId = [
  "dashboard",
  "facilites",
  "auctions",
  "upcoming-auctions",
  "users",
  "transactions",
  "admins",
  "permissions",
  "subscribed",
  "facility-auction",
  "users-own",
  "admins-own",
  "information",
] as any;

export const getYear = () => {
  const now = new Date().getFullYear();
  const start = Number(now) - 3;
  const finish = Number(now) + 1;
  const grouping: any = [];

  for (let year = start; year <= finish; year++) {
    grouping.push({ id: year, value: year.toString() });
  }

  const sort = grouping.sort().reverse();

  return sort;
};

export const getMonth = [
  {
    id: "1",
    value: "January",
  },
  {
    id: "2",
    value: "February",
  },
  {
    id: "3",
    value: "March",
  },
  {
    id: "4",
    value: "April",
  },
  {
    id: "5",
    value: "May",
  },
  {
    id: "6",
    value: "June",
  },
  {
    id: "7",
    value: "July",
  },
  {
    id: "8",
    value: "August",
  },
  {
    id: "9",
    value: "September",
  },
  {
    id: "10",
    value: "October",
  },
  {
    id: "11",
    value: "November",
  },
  {
    id: "12",
    value: "December",
  },
] as any;

export const filterTypesAuction = [
  {
    id: "online",
    value: "Online",
  },
  {
    id: "in_person",
    value: "In Person",
  },
  {
    id: "caravan",
    value: "Caravan",
  },
] as any;

export const filterTimeZone = [
  {
    id: "HST",
    value: "(HST) Hawaii-Aleutian Standard Time",
  },
  {
    id: "AKST",
    value: "(AKST) Alaska Standard Time",
  },
  {
    id: "PST",
    value: "(PST) Pacific Standard Time",
  },
  {
    id: "MST",
    value: "(MST) Mountain Standard Time",
  },
  {
    id: "CST",
    value: "(CST) Central Standard Time",
  },
  {
    id: "EST",
    value: "(EST) Eastern Standard Time",
  },
  {
    id: "DST",
    value: "(DST) Daylight Saving Time",
  },
] as any;
